


























import { ILearnPackContent } from '@/models';
import { LEARNPACK_CONTENT_TYPE, LEARNPACK_CONTENT_TYPE_LABEL } from '@/utils';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'LearnPackDetailListItem',
  props: {
    data: {
      type: Object as PropType<ILearnPackContent>,
      required: true,
    },
  },
  setup(props) {
    const amount = computed(() => {
      if (props.data.elementType === LEARNPACK_CONTENT_TYPE.Lecture) {
        if (props.data.length === -1 || !props.data.length) {
          return '총 - 시간';
        }
        const HOUR_SEC = 3600;
        const hour = (props.data.length / HOUR_SEC).toFixed(1);

        return `총 ${hour}시간`;
      }

      if (props.data.elementType === LEARNPACK_CONTENT_TYPE.Book) {
        return `총 ${props.data.length?.toLocaleString()}쪽`;
      }

      return '';
    });

    const price = computed(() => {
      if (props.data.price === 0) {
        return '무료';
      }
      return `${props.data.price?.toLocaleString()}원`;
    });

    function handleClick() {
      if (!props.data.link) return;

      window.open(props.data.link, '_blank');
    }
    return {
      LEARNPACK_CONTENT_TYPE,
      LEARNPACK_CONTENT_TYPE_LABEL,

      amount,
      price,

      handleClick,
    };
  },
});
