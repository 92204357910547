














































































import { ILearnPack } from '@/models';
import { learnPackSearvice } from '@/services';
import { bookMarkSearvice } from '@/services/bookMark';
import { AccountStore } from '@/store/modules';
import { CONTENT_TYPE, getToken } from '@/utils';
import { computed, defineComponent, onMounted, reactive, ref } from '@vue/composition-api';
import copy from 'copy-to-clipboard';
import { ListItem } from './components';

export default defineComponent({
  name: 'LearnPackDetail',
  props: {
    learnPackId: {
      type: String,
    },
  },
  components: {
    ListItem,
  },
  // metaInfo: {
  //   title: '',
  //   meta: [{ name: 'name', content: 'content' }],
  // },
  setup(props, { root }) {
    const { set } = root.$meta().addApp('custom');

    const isLoading = ref(false);

    const learnPackDetail = reactive<ILearnPack>({
      id: -1,
      title: '',
      bookMarkId: -1,
      bookMarked: false,
      contentAmount: 0,
      introduction: null,
      learnPackElements: [],
      tags: [],
      userDetail: {
        email: '',
        nickName: '',
        introduction: '',
        position: '',
        profileUrl: '',
        referenceUrls: [],
        userId: -1,
      },
    });

    const referenceUrls = computed(() =>
      learnPackDetail.userDetail.referenceUrls.filter(item => !!item.url),
    );

    async function fetchData() {
      if (isLoading.value) return;

      isLoading.value = true;
      try {
        const data = await learnPackSearvice.getDetail(Number(props.learnPackId));
        learnPackDetail.id = data.id;
        learnPackDetail.title = data.title;
        learnPackDetail.bookMarkId = data.bookMarkId;
        learnPackDetail.bookMarked = data.bookMarked;
        learnPackDetail.contentAmount = data.contentAmount;
        learnPackDetail.introduction = data.introduction;
        learnPackDetail.learnPackElements = data.learnPackElements;
        learnPackDetail.tags = data.tags;
        learnPackDetail.userDetail = data.userDetail;

        set({
          meta: [
            { vmid: 'og:title', property: 'og.title', content: data.title ?? '' },
            {
              vmid: 'og:description',
              property: 'og:description',
              content: data.introduction ?? '',
            },
            {
              vmid: 'og:image',
              property: 'og:image',
              content: data.learnPackElements
                ? data?.learnPackElements[0].thumbnail
                : 'https://learnmoa.com/img/learnmoa-description.png',
            },
            {
              vmid: 'og:url',
              property: 'og:url',
              content: `${window.location.origin}/learn-pack/${data.id}`,
            },

            { vmid: 'title', name: 'title', content: data.title ?? '' },
            { vmid: 'description', name: 'description', content: data.introduction ?? '' },
          ],
        });
      } catch {
        //
      } finally {
        isLoading.value = false;
      }
    }

    async function handleClickBookmark() {
      if (!AccountStore.userId || !getToken()) {
        alert('로그인 후 이용해 주세요');
        return;
      }

      if (isLoading.value) return;

      isLoading.value = true;

      try {
        if (learnPackDetail.bookMarked) {
          await bookMarkSearvice.deleteBookMark(learnPackDetail.bookMarkId);
        } else {
          const bookmarkId = await bookMarkSearvice.createBookMark({
            contentId: learnPackDetail.id,
            contentType: CONTENT_TYPE.LearnPack,
          });

          learnPackDetail.bookMarkId = bookmarkId;
        }

        learnPackDetail.bookMarked = !learnPackDetail.bookMarked;
      } catch {
        //
      } finally {
        isLoading.value = false;
      }
    }

    function handleClickReference(url: string) {
      window.open(url, '_blank');
    }

    async function handleCopyUrl() {
      //
      const copied = await copy(window.location.href);
      if (copied) {
        alert('주소가 복사되었습니다.');
      }
    }

    onMounted(async () => {
      if (props.learnPackId) {
        await fetchData();
      }
    });

    return {
      learnPackDetail,

      referenceUrls,

      handleClickBookmark,
      handleClickReference,
      handleCopyUrl,
    };
  },
});
